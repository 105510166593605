import { Button, Form, Input, Modal, Switch } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { resetBranding, setBranding } from '../../redux/branding';

interface OmmaxLayoutBrandingModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const OmmaxLayoutBrandingModal: React.FC<OmmaxLayoutBrandingModalProps> = (
  params,
) => {
  const { isOpen, setIsOpen} = params;

  const [form] = Form.useForm();
  const branding = useSelector((state: RootState) => state.branding.data);
  const dispatch = useDispatch();

  // on change of current, set values
  useEffect(() => {
    form.setFieldsValue(branding);
  }, [branding]);

  // initially, set value from local storage
  useEffect(() => {
    const store = localStorage.getItem("DELPHI_BRANDING");
    if (store) {
        try {
            console.log(JSON.parse(store))
            dispatch(setBranding(JSON.parse(store)));
        }
        catch (e) {
            console.error(e)
        }
    }
  }, []);

  const save = async () => {
    const fieldsValue = await form.validateFields();
    const values = {
      ...fieldsValue,
      logoUrlWidth: fieldsValue.logoUrlWidth ? Number(fieldsValue.logoUrlWidth) : undefined,
      logoUrlHeight: fieldsValue.logoUrlHeight ? Number(fieldsValue.logoUrlHeight) : undefined,
      showPocWarning: true
    }
    dispatch(setBranding({...values, isLocalPreviewMode: true}));
    setIsOpen(false);
  };

  const reset = async () => {
    dispatch(resetBranding());
    setIsOpen(false);
  };


  return (
    <>
      <Modal
        title={"Edit Delphi Dialogue Branding"}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        onOk={() => save()}
        width={600}
      >
        <Form
          form={form}
          name="branding"
          {...formItemLayout}
          onFinish={save}
          style={{ marginTop: 15 }}
        >
          <Form.Item
            name="brand"
            label="Brand name"
          >
                <Input placeholder="OMMAX"/>
            </Form.Item>
          <Form.Item
            name="logoUrl"
            label="Logo URL"
          >
                <Input placeholder="https://www.ommax-digital.com/typo3conf/ext/sitetemplate/Resources/Public/img/logo-header.svg"/>
            </Form.Item>
          <Form.Item
            name="logoUrlWidth"
            label="Logo width in pixels"
          >
                <Input placeholder="100"/>
            </Form.Item>
          <Form.Item
            name="logoUrlHeight"
            label="Logo height in pixels"
          >
                <Input placeholder="20"/>
            </Form.Item>

            
        <Form.Item valuePropName="checked" name="showNavitems" label="Show nav items">
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>

        <Form.Item valuePropName="checked" name="showWorkspaces" label="Show workspaces">
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
        </Form>

        

        <Button danger onClick={() => reset()}>Delete branding customization</Button>
      </Modal>
    </>
  );
};

export default OmmaxLayoutBrandingModal;
