import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Branding {
  data: {
    brand?: string;
    logoUrl?: string;
    logoUrlWidth?: number;
    logoUrlHeight?: number;
    colorPrimary?: string;
    colorSecondary?: string;
    showWorkspaces?: boolean;
    showNavitems?: boolean;
    showPocWarning?: boolean;
    isLocalPreviewMode?: boolean;
  }
}

const initialState: Branding = {
  data: {
    brand: undefined,
    logoUrl: undefined,
    logoUrlWidth: undefined,
    logoUrlHeight: undefined,
    colorPrimary: undefined,
    colorSecondary: undefined,  
    showWorkspaces: true,
    showNavitems: true,
    showPocWarning: false,
    isLocalPreviewMode: false
  }
};

const slice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    setBranding: (state, action: PayloadAction<Branding>) => {
      console.log(action.payload);
      state.data = { ...state.data, ...action.payload };
      localStorage.setItem("DELPHI_BRANDING", JSON.stringify(state.data));
    },
    resetBranding: (state) => {
      state.data = initialState.data;
      localStorage.removeItem("DELPHI_BRANDING");
    },
  },
});

export const { setBranding, resetBranding } = slice.actions;

export default slice.reducer;
