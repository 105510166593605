import { Layout } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createWorkspace } from '../../data/workspace';
import { AppDispatch, RootState } from '../../redux';
import {
  fetchFiles,
  fetchWorkspaces,
  setWorkspace
} from '../../redux/workspaces';
import ChatWindow from './components/ChatWindow';
import WorkspaceMenu from './components/WorkspaceMenu';

const { Header, Content, Sider } = Layout;

const ChatMain: React.FC = () => {
  const { auth } = useSelector((state: RootState) => state.auth);
  const { showWorkspaces } = useSelector((state: RootState) => state.branding.data);
  const { workspaceList, workspace, loading } = useSelector(
    (state: RootState) => state.workspaces,
  );
  const { workspaceId } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    // if we set this param via url, the worspaceList is not feteched on initial execution
    // therefore only dispatch if 1) workspace unset 2) workpsace list present
    // don't need an effect on workspace as we do not want to re-execute effect
    if (workspaceList) {
      dispatch(setWorkspace(workspaceId));
      dispatch(fetchFiles()).unwrap();
    }

    // if we do not have a default workspace defined, redirect to first one
    if (workspaceList?.length && !workspace && !workspaceId)
      navigate('/workspace/' + workspaceList[0]._id);
  }, [workspaceId, workspaceList]);

  const createDefaultWorkspace = async () => {
    await createWorkspace({
      name: auth!.given_name + "'s Workspace",
    });
    dispatch(fetchWorkspaces()).unwrap();
  };

  useEffect(() => {
    // create default workspace on initial loading
    if (loading === 'succeeded' && workspaceList?.length === 0) {
      createDefaultWorkspace();
    }
  }, [workspaceList, loading]);

  return (
    <Layout style={{ minHeight: '80vh' }}>
      {showWorkspaces && <WorkspaceMenu />}
      <Layout>
        <ChatWindow />
      </Layout>
      {/* <SettingsMenu /> */}
    </Layout>
  );
};

export default ChatMain;
