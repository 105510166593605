import { FileOutlined, InboxOutlined, LeftOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Layout,
  message,
  Result,
  Row,
  Spin
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { FullPageChat } from 'flowise-embed-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BarLoader } from 'react-spinners';
import { ApplicationModel } from '../../../data/application';
import { WORKSPACE_PERMISSION_LEVEL } from '../../../data/share';
import { AppDispatch, RootState } from '../../../redux';
import { fetchApps, fetchOwnshare, fetchSyncStatus } from '../../../redux/workspaces';
import useFileUpload from '../../../utils/useFileUpload';
import useWorkspace from '../../../utils/useWorkspace';
import ChatWindowApps from './ChatWindowApps';
import FileDrawer from './FileDrawer';

const { Content } = Layout;

enum Page {
  APP_LIST,
  APP,
}

const ChatWindow: React.FC = () => {
  const { showWorkspaces, brand } = useSelector((state: RootState) => state.branding.data);
  const { isFileDragging } = useSelector((state: RootState) => state.file);
  const { auth } = useSelector((state: RootState) => state.auth);
  const {
    workspace,
    loadingApps,
    workspaceApps,
    workspaceFiles,
    workspaceSyncStatus,
  } = useSelector((state: RootState) => state.workspaces);
  const [app, setApp] = useState<ApplicationModel>();
  const [page, setPage] = useState<Page | undefined>(undefined);
  const [isFileDrawerOpen, setIsFileDrawerOpen] = useState<boolean>(false);
  const currentChathistoryPrefix = useRef<string>();
  const [showReloadChatScreen, setShowReloadChatScreen] =
    useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const [messageApi, contextHolder] = message.useMessage();
  const { isUploading, getUploadProps } = useFileUpload();
  const { checkCurrentWorkspacePermissions } = useWorkspace();

  const ingestionCount = workspaceSyncStatus?.waiting_for_ingestion?.count;
  const deletionCount = workspaceSyncStatus?.waiting_for_deletion?.count;
  const isAdmin = checkCurrentWorkspacePermissions(
    WORKSPACE_PERMISSION_LEVEL.MANAGE,
  );

  const updateAppsList = async () => {
    await dispatch(fetchApps()).unwrap();
  };


  const storeCurrentHistory = () => {
    if (!app) return;

    const currCache = localStorage.getItem(`${app.flowise.id}_EXTERNAL`);
    if (currCache) {
      localStorage.setItem(
        `${app.flowise.id}_EXTERNAL_DELPHI+${currentChathistoryPrefix.current}`,
        currCache,
      );
    } else {
      localStorage.removeItem(
        `${app.flowise.id}_EXTERNAL_DELPHI+${currentChathistoryPrefix.current}`,
      );
    }
  }

  useEffect(() => {
    if (ingestionCount || deletionCount) {
      const interval = setInterval(() => {
        dispatch(fetchSyncStatus()).unwrap();
      }, 10000);

      return () => clearInterval(interval);
    } else {
      const failedCount = workspaceSyncStatus?.failed?.count;
      if (failedCount)
        messageApi.error(
          failedCount + ' files could not be processed for ' + workspace?.name,
        );
    }
  }, [ingestionCount, deletionCount]);

  useEffect(() => {
    if (loadingApps === 'succeeded' && page === undefined) {
      setApp(workspaceApps?.find((a) => a.isGlobalDefault));
      setPage(Page.APP);
    } else if (app && !workspaceApps?.find((a) => a._id === app!._id)) {
      setApp(undefined);
      setPage(Page.APP_LIST);
    }
  }, [loadingApps, app]);

  useEffect(() => {
    if (app && workspace) {
      // to be fixed directly in flowise code
      // currently, flowise can only handle one history per chatflow id
      // therefore, we reset the id on eery app / workspace change and hide the UI for a few seconds to enforce rebuild (as there is no useEffect on the chat id)
      setShowReloadChatScreen(true);

      // store current history for later use
      storeCurrentHistory();
      currentChathistoryPrefix.current = `${app._id}+${workspace._id}`;

      // retrieve new history
      const newCache = localStorage.getItem(
        `${app.flowise.id}_EXTERNAL_DELPHI+${currentChathistoryPrefix.current}`,
      );

      if (newCache)
        localStorage.setItem(`${app.flowise.id}_EXTERNAL`, newCache);
      else localStorage.removeItem(`${app.flowise.id}_EXTERNAL`);

      setTimeout(() => {
        setShowReloadChatScreen(false);
      }, 500);
    }
  }, [app, workspace]);

  useEffect(() => {
    if (app) {
      setPage(Page.APP);
    }
  }, [app]);
  useEffect(() => {
    if (workspace) {
      updateAppsList();
      dispatch(fetchSyncStatus()).unwrap();
      dispatch(fetchOwnshare()).unwrap();
    }
  }, [workspace]);
  
  useEffect(() => {
    const unloadCallback = () => {
        storeCurrentHistory()
    };
    
    window.addEventListener("beforeunload", unloadCallback);
    return () => {
        window.removeEventListener("beforeunload", unloadCallback);
    }
}, [app]);

  const getPage = () => {
    if ((isFileDragging || isUploading) && !isFileDrawerOpen && isAdmin) {
      return (
        <Row
          justify="space-around"
          align="middle"
          style={{ marginTop: 100, paddingLeft: 20, paddingRight: 20 }}
        >
          <Col span={24}>
            <Dragger {...getUploadProps(workspace!, messageApi)}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a PDF files only at the moment.
              </p>
            </Dragger>
          </Col>
        </Row>
      );
    } else if (page === Page.APP_LIST) {
      return <ChatWindowApps setApp={setApp} />;
    } else {
      if (!app)
        return (
          <Result
            status="404"
            title="App not found"
            subTitle="Sorry, the workspace or app you requested was not found."
          />
        );
      if (showReloadChatScreen) {
        return <Spin style={{ height: 100 }}></Spin>;
      }

      return (
        <>
          <FullPageChat
            chatflowid={app!.flowise!.id}
            // @ts-ignore
            chatId={workspace?._id + '_' + auth?.sub}
            apiHost="https://flowise.delphi-dialogue.com"
            chatflowConfig={{
              vars: {
                customerId: workspace?._id + '+' + auth?.sub,
              },
              qdrantCollection: workspace?._id,
              sessionId: workspace?._id + '+' + auth?.sub,
              returnSourceDocuments: true,
            }}
            // @ts-ignore
            theme={{
              chatWindow: {
                showTitle: true,
                title: 'Delphi Dialogue | ' + app!.name,
                titleAvatarSrc:
                  'https://raw.githubusercontent.com/walkxcode/dashboard-icons/main/svg/google-messages.svg',
                welcomeMessage: brand ? 'Welcome to '+brand+'!' : 'Welcome to Delphi Dialogue!',
                backgroundColor: '#ffffff',
                height: 700,
                fontSize: 16,
                poweredByTextColor: '#303235',
                botMessage: {
                  backgroundColor: '#f7f8ff',
                  textColor: '#303235',
                  showAvatar: true,
                  avatarSrc: 'https://www.ommax-digital.com/favicon.ico',
                },
                userMessage: {
                  backgroundColor: '#2077DA',
                  textColor: '#ffffff',
                  showAvatar: true,
                  avatarSrc:
                    'https://raw.githubusercontent.com/zahidkhawaja/langchain-chat-nextjs/main/public/usericon.png',
                },
                textInput: {
                  placeholder: 'How can Delphi help you today?',
                  backgroundColor: '#ffffff',
                  textColor: '#303235',
                  sendButtonColor: '#11BADD',
                },
                feedback: {
                  color: '#303235',
                },
                footer: {
                  textColor: '#303235',
                  text: 'Powered by',
                  company: 'OMMAX Delphi Dialogue',
                  companyLink: 'https://www.ommax.de',
                },
              },
            }}
          />
        </>
      );
    }
  };

  const statusMessage = () => {
    const ingestionCount = workspaceSyncStatus?.waiting_for_ingestion?.count;
    const deletionCount = workspaceSyncStatus?.waiting_for_deletion?.count;

    if (ingestionCount && !deletionCount) {
      return `Ingestion of ${ingestionCount} file${ingestionCount > 1 ? 's' : ''} in progress`;
    }
    if (!ingestionCount && deletionCount) {
      return `Deletion of ${deletionCount} file${deletionCount > 1 ? 's' : ''} in progress`;
    }
    if (ingestionCount && deletionCount) {
      return `Ingestion of ${ingestionCount} file${ingestionCount > 1 ? 's' : ''} and deletion of ${deletionCount} file${deletionCount > 1 ? 's' : ''} in progress`;
    }
    return 'No operations in progress';
  };

  return (
    <Content
      style={{
        margin: showWorkspaces ? '0px 16px' : undefined,
        padding: 0,
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        {((workspaceSyncStatus?.waiting_for_deletion?.count || 0) > 0 ||
          (workspaceSyncStatus?.waiting_for_ingestion?.count || 0) > 0) && (
          <Row
            justify="center"
            style={{
              padding: 5,
              backgroundColor: '#000F50',
              color: 'white',
              borderBottom: 1,
              borderBottomColor: '#f0efed',
              borderBottomStyle: 'solid',
            }}
          >
            <Col style={{ width: 500 }}>
              <div style={{ textAlign: 'center' }}>{statusMessage()}</div>
              <div style={{ marginTop: 6 }}>
                <BarLoader color={'white'} width={500} />
              </div>
            </Col>
          </Row>
        )}
        <Row
          justify="space-between"
          style={{
            padding: 5,
            backgroundColor: '#3A00D4',
            color: 'white',
            borderBottom: 1,
            borderBottomColor: '#f0efed',
            borderBottomStyle: 'solid',
          }}
        >
          <Col style={{ width: 100 }}>
            {page === Page.APP && (
              <Button onClick={() => setPage(Page.APP_LIST)}>
                <LeftOutlined /> App List
              </Button>
            )}
          </Col>
          <Col>
            <p style={{ fontSize: 20, margin: 3 }}>
              {page === Page.APP_LIST ? 'App List' : app?.name}
            </p>
          </Col>
          <Col>
            <Badge count={workspaceFiles?.length} color="default">
              <Button onClick={() => setIsFileDrawerOpen(true)}>
                <FileOutlined /> Files
              </Button>
            </Badge>
          </Col>
        </Row>
        <Spin spinning={loadingApps === 'pending'}>{getPage()}</Spin>
      </div>
      <FileDrawer
        isVisible={isFileDrawerOpen}
        setIsVisible={setIsFileDrawerOpen}
      />
      {contextHolder}
    </Content>
  );
};

export default ChatWindow;
